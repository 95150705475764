import React, { useState } from "react"

export default function SmartText({ text, length = 200 }) {
  const [showLess, setShowLess] = useState(true)

  if (text.length < length) {
    return (
      <p
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      ></p>
    )
  }

  return (
    <div>
      <p
        style={{ whiteSpace: "pre-wrap", marginBottom: "5px" }}
        dangerouslySetInnerHTML={{
          __html: showLess ? `${text.slice(0, length)}...` : text,
        }}
      ></p>
      <span
        style={{
          color: "blue",
          cursor: "pointer",
          fontWeight: "bold",
          color: "#1786ed",
        }}
        onClick={() => setShowLess(!showLess)}
      >
        {showLess ? "More" : "Less"}
      </span>
    </div>
  )
}
