import React, { Component } from 'react';
import "./loadmore.css"

class Loadmore extends Component {
  constructor(props) {
    super(props);

    this.state = {
        visible: props.visible,
        increment: props.increment,
        loaded: props.loaded
    }
  }

  loadmore() {
    this.setState((prevState) => ({
      visible: prevState.visible + this.state.increment,
      loaded : true
    }));
  }
    
  loadless() {
    this.setState((prevState) => ({
      visible: prevState.visible - this.state.increment,
      loaded : false
    }));
  }    

  render() {
    const { children } = this.props;
    const { visible, loaded} = this.state;

    return (
      <div className="load-more">
        { children.slice(0, visible).map((e) => e) }
        { children.length > visible ?
          <div className='loadmore-btn-container'>
            <button className='loadmore-btn' onClick={() => this.loadmore()}>More</button>
          </div>
                :
            loaded ?
          <div className='loadmore-btn-container'>
            <button className='loadmore-btn' onClick={() => this.loadless()}>Less</button>
          </div>
           :null }
      </div>
    );
  }
}

export default Loadmore;
