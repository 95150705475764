import { Modal, Divider, Table, Card, Button, Popconfirm } from "antd"
import React, { useState } from "react"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"

function Window(props) {
  const report = props.m
  const reportFiles = report.childrenReportFile
    .sort((a, b) => {
      return a.order - b.order
    })
    .map(rpt => {
      return {
        key: rpt.key ? rpt.key : rpt.name, //antd table select need a key property for each entry
        ...rpt,
      }
    })

  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => {
    setIsModalVisible(true)
  }
  const hideModal = () => {
    setIsModalVisible(false)
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
  ]

  const [selectedRowKeys, setSelectedRowKeys] = useState([]) //countrolled the selectedRowKeys(antd reserved keyword!)
  const [selectedRow, setSelectedRow] = useState({})

  const rowSelection = {
    onChange: selectedRowKeys => {
      setSelectedRowKeys(selectedRowKeys) //update selected key according to radio selection
    },
  }

  return (
    <div>
      <button onClick={showModal}>{report.date}</button>
      <Modal
        width="895px"
        visible={isModalVisible}
        onCancel={hideModal}
        title={report.date}
        footer={null}
        centered
        getContainer={false}
        shouldFocusAfterRender={false}
      >
        {report.note ? (
          <p>
            {report.note} <br />
          </p>
        ) : null}

        <div className="w-full flex flex-col-reverse sm:flex-row justify-between gap-5 py-8">
          <div className="">
            <Table
              style={{ width: 280 }}
              className="m-0 p-0"
              rowSelection={{
                type: "radio",
                selectedRowKeys: selectedRowKeys,
                ...rowSelection,
              }}
              onRow={r => ({
                onClick: () => {
                  // console.log(r, r.key, "r")
                  setSelectedRow(r)
                  setSelectedRowKeys([r.key]) //update selected key according to row select, be mindful of data format
                },
              })}
              columns={columns}
              dataSource={reportFiles}
              pagination={{ position: ["bottomCenter"] }}
            />
          </div>

          <div>
            {selectedRowKeys.length === 0 ? null : (
              <Card style={{ width: 540 }} title={selectedRow.name}>
                <div className="w-full">
                  {selectedRow.type === "application/pdf" ? (
                    <iframe
                      src={
                        selectedRow.downloadURL +
                        `#scrollbars=1&toolbar=0&statusbar=1`
                      }
                      name="pdfpreview"
                      title={selectedRow.name}
                      width="500"
                      height="500"
                      frameBorder="1"
                    ></iframe>
                  ) : selectedRow.type === "image/png" ||
                    selectedRow.type === "image/jpeg" ? (
                    <TransformWrapper initialScale={1}>
                      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                        <React.Fragment>
                          <div className="flex gap-5 justify-end pb-3 pr-3">
                            <button onClick={() => zoomIn()}>
                              <b>zoom in +</b>
                            </button>
                            <button onClick={() => zoomOut()}>
                              <b>zoom out -</b>
                            </button>
                            <button onClick={() => resetTransform()}>
                              <b>reset</b>
                            </button>
                          </div>
                          <div className="flex justify-center content-center">
                            <TransformComponent>
                              <img src={selectedRow.downloadURL} alt="" />
                            </TransformComponent>
                          </div>
                        </React.Fragment>
                      )}
                    </TransformWrapper>
                  ) : (
                    <></>
                  )}
                </div>
              </Card>
            )}
          </div>
        </div>
      </Modal>
    </div>
  )
}
export default Window
