import { Modal } from "antd"
import React, { useState, useEffect } from "react"
import { authService } from "../services/auth.service"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"

function MediaModal({ visible, onCancel, data }) {
  useState(() => {
    if (authService.isBrowser) {
      document.oncontextmenu = function (e) {
        /*prevent the right click default event*/
        e = e || window.event
        return false
      }
    }
  })

  const widthImg = 650
  const widthPdf = 1050

  return (
    <Modal
      title={data.name}
      footer={null}
      getContainer={false}
      centered="true"
      visible={visible}
      onCancel={onCancel}
      destroyOnClose
      width={data.type === "application/pdf" ? widthPdf : widthImg}
    >
      {data.type === "image/png" || data.type === "image/jpeg" ? (
        <TransformWrapper initialScale={1}>
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <React.Fragment>
              <div className="flex gap-5 justify-end pb-3 pr-3">
                <button onClick={() => zoomIn()}>
                  <b>zoom in +</b>
                </button>
                <button onClick={() => zoomOut()}>
                  <b>zoom out -</b>
                </button>
                <button onClick={() => resetTransform()}>
                  <b>reset</b>
                </button>
              </div>
              <div className="flex justify-center content-center">
                <TransformComponent>
                  <img src={data.downloadURL} alt="" />
                </TransformComponent>
              </div>
            </React.Fragment>
          )}
        </TransformWrapper>
      ) : data.type === "application/pdf" ? (
        <iframe
          src={data.downloadURL + `#scrollbars=0&toolbar=0&statusbar=0`}
          name="pdfpreview"
          title={data.name}
          width="1000"
          height="700"
          frameBorder="0"
        ></iframe>
      ) : (
        <></>
      )}
    </Modal>
  )
}
export default MediaModal
