import Layout from "../components/layout"
import React, { useState } from "react"
import { navigate } from "gatsby"
import { Row, Col, Divider, Timeline, Tag, Collapse } from "antd"
import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"
// import VideoGallery from "./VideoGallery"
import Seo from "../components/seo"
import Loadmore from "../components/loadMore"
import Window from "../components/Window"
import SmartText from "../components/readMore"
import MediaModal from "../components/mediaModal"
import "./style.css"
import Platinum from "../images/seal/platinum.png"
import Gold from "../images/seal/gold.png"
import Silver from "../images/seal/silver.png"
import Bronze from "../images/seal/bronze.png"

const { Panel } = Collapse

const InsDetail = props => {
  const [mediaModal, setMediaModal] = useState(false)
  const [mediaData, setMediaData] = useState({})

  //destructure the props, take the db content
  var info = props.pageContext.node
  //onsole.log(info)

  let sealsrc = null
  switch (info.seal) {
    case "Platinum":
      sealsrc = Platinum
      break
    case "Gold":
      sealsrc = Gold
      break
    case "Silver":
      sealsrc = Silver
      break
    case "Bronze":
      sealsrc = Bronze
      break
    default:
      sealsrc = null
  }


  // let qrsrc = "https://syfir.s3.us-east-2.amazonaws.com/qr-code/" + info.invNumber +".png"

  //match for 2 folders in Media, and map the files inside
  var isCert = false,
    isVideo = false,
    isPress = false,
    isRef = false
  var cert = [],
    proImg = [],
    video = [],
    press = [],
    reference = [],
    provLst = []

  var rptLst = info.childrenReport.map((m, i) => {
    return <Window m={m} key={i} />
  })

  var techDetail = info.childrenMedia
    .filter(s => s.name.includes("PublicTechnical"))
    .map((s, i) => {
      return (
        <Panel header={s.name.substring(15)} key={i}>
          {s.childrenMediaFile
            .filter(t => t.public === true)
            .sort((a, b) => {
              return a.order - b.order
            })
            .map((f, j) => {
              return (
                <div className="pb-3 preview" key={j}>
                  {f.type !== null ? (
                    <a
                      rel="noreferrer"
                      target="_blank"
                      onClick={() => {
                        setMediaModal(true)
                        setMediaData(f)
                      }}
                    >
                      {f.name}
                    </a>
                  ) : (
                    <a
                      href={f.downloadURL ? f.downloadURL : f.url}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {f.name}
                    </a>
                  )}

                  <br />
                </div>
              )
            })}
        </Panel>
      )
    })

  info.childrenMedia.forEach((m, i) => {
    if (m.name === "PublicPhoto") {
      //order the profile image by "order",restrueture for image gallery format
      //console.log(m.childrenMediaFile)
      proImg = m.childrenMediaFile
        .filter(t => t.public === true)
        .sort((a, b) => {
          return a.order - b.order
        })
        .map((f, j) => {
          return {
            original: f.downloadURL,
            thumbnail: f.downloadURL,
          }
        })
      //console.log(proImg)
    } else if (m.name === "PublicVideo") {
      video = m.childrenMediaFile
        .filter(t => t.public === true)
        .sort((a, b) => {
          return a.order - b.order
        })
        .map((f, j) => {
          isVideo = true
          return (
            <div className="pb-3" key={j}>
              <a href={f.url} rel="noreferrer" target="_blank">
                {f.name}
              </a>
              <br />
            </div>
          )
        })
    } else if (m.name === "PublicCertificate") {
      cert = m.childrenMediaFile
        .filter(t => t.public === true)
        .sort((a, b) => {
          return a.order - b.order
        })
        .map((f, j) => {
          isCert = true
          return (
            <div className="pb-3 preview" key={j}>
              {f.type !== null ? (
                <a
                  rel="noreferrer"
                  target="_blank"
                  onClick={() => {
                    setMediaModal(true)
                    setMediaData(f)
                  }}
                >
                  {f.name}
                </a>
              ) : (
                <a
                  href={f.downloadURL ? f.downloadURL : f.url}
                  rel="noreferrer"
                  target="_blank"
                >
                  {f.name}
                </a>
              )}

              <br />
            </div>
          )
        })
    } else if (m.name === "PublicPress") {
      press = m.childrenMediaFile
        .filter(t => t.public === true)
        .sort((a, b) => {
          return a.order - b.order
        })
        .map((f, j) => {
          isPress = true
          return (
            <div className="pb-3 preview" key={j}>
              {f.type !== null ? (
                <a
                  rel="noreferrer"
                  target="_blank"
                  onClick={() => {
                    setMediaModal(true)
                    setMediaData(f)
                  }}
                >
                  {f.name}
                </a>
              ) : (
                <a
                  href={f.downloadURL ? f.downloadURL : f.url}
                  rel="noreferrer"
                  target="_blank"
                >
                  {f.name}
                </a>
              )}

              <br />
            </div>
          )
        })
    } else if (m.name === "PublicReference") {
      reference = m.childrenMediaFile
        .filter(t => t.public === true)
        .sort((a, b) => {
          return a.order - b.order
        })
        .map((f, j) => {
          isRef = true
          return (
            <div className="pb-3 preview" key={j}>
              <a
                rel="noreferrer"
                target="_blank"
                onClick={() => {
                  setMediaModal(true)
                  setMediaData(f)
                }}
              >
                {f.name}
              </a>
              <br />
            </div>
          )
        })
    }
  })
  //if no data push to proImg array, set the field proImg to the array
  if (proImg.length === 0) {
    proImg[0] = { original: info.profileImg, thumbnail: info.profileImg }
  }

  //sort without the present owner
  provLst = info.childrenProvenance
    .filter(p => p.endTime !== "Present")
    .sort(provSort)

  //add the present owner(if it exist) to the top after sorting
  var owner = info.childrenProvenance.find(p => p.endTime === "Present")
  provLst = (owner ? [owner, ...provLst] : provLst).map((prov, i) => (
    <Timeline.Item key={i}>
      <b>{prov.name}</b>
      <br />
      {prov.startTime} - {prov.endTime}
      <br />
      {/* {prov.note} */}
    </Timeline.Item>
  ))

  var _imageGallery = 0
  const _onImageClick = event => {
    setMediaModal(true)
    setMediaData({
      downloadURL: event.target.src,
      type: "image/png",
      name: "profile image: " + (1 + _imageGallery.getCurrentIndex()),
    })
  }

  return (
    <Layout>
      {/* {console.log(proImg[0].thumbnail)}
      {console.log(info.name)} */}

      <Seo
        title={info.name}
        description="CCI IMIR™"
        image={proImg[0].thumbnail}
      />

      <div className="mt-6 flex flex-row justify-between">
        <button
          className="h-8 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
          onClick={() => navigate("/search")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M11 17l-5-5m0 0l5-5m-5 5h12"
            />
          </svg>
          <span> Back</span>
        </button>
        <div className="mr-8 sm:mr-12">

        {/* {info.qrCode == true ? (
            <img
              className="h-14 -mb-6"
              style={{ marginTop: "-2px" }}
              src={qrsrc}
              alt="QR Code"
            />
          ) : null} */}

          {info.sourceLogo ? (
            <img src={info.sourceLogo} className="h-12" alt="sourceLogo" />
          ) : null}
          
          {/* Si-Yo Seal hidden on request, Aug 26, 2022 */}
          {/* {sealsrc ? (
            <img
              className="h-24 -mb-6"
              style={{ marginTop: "-2px" }}
              src={sealsrc}
              alt="seal"
            />
          ) : null} */}
        </div>
      </div>

      {/* container */}
      <div className="px-3 max-w-full">
        <div className="grid grid-cols-7 gap-7 ">
          {proImg[0].original !== "" ? (
            <div className=" hidden items-start sm:block sm:col-span-3 ">
              {/* <Carousel autoplay>{proImg}</Carousel> */}

              <ImageGallery
                ref={i => (_imageGallery = i)}
                items={proImg}
                onClick={_onImageClick}
                showPlayButton={false}
                showBullets={false}
                showIndex={false}
                showThumbnails={true}
                lazyLoad={true}
                autoPlay={false}
                showNav={false}
                showFullscreenButton={false}
              />
            </div>
          ) : (
            ""
          )}

          <div className="items-start col-span-7 sm:col-span-4">
            <div className="text-lg py-1 font-semibold ">{info.invNumber}</div>
            <div className="text-2xl pb-1 font-semibold">
              {info.name} - {info.type}
            </div>
            {info.year ? (
              <span className="text-lg py-1">{info.year} </span>
            ) : null}

            {info.city ? (
              <span className="text-lg py-1">{info.city}, </span>
            ) : null}
            {info.country ? (
              <span className="text-lg py-1">{info.country}</span>
            ) : null}

            {info.maker === "undefined undefined" ? (
              <div className="hidden text-lg  py-1"></div>
            ) : (
              <div className=" text-lg  py-1 ">{info.maker}</div>
            )}

            <div className="">
              <div className="inline-block ">
                {info.source ? (
                  <div className="py-2">
                    <b>Source</b>
                    <br />
                    {info.source}
                  </div>
                ) : null}

                {info.sourceUrl ? (
                  <div className="py-2">
                    <b>Source URL</b>
                    <br />
                    <a href={info.sourceUrl}>{info.sourceUrl}</a>
                  </div>
                ) : null}

                {info.dimension ? (
                  <div className="py-2" id="dimension">
                    <b>Dimension</b>
                    <br />
                    {info.dimension}
                  </div>
                ) : null}

                {/* {info.valueInvestor ? (
                  <Tag color="blue">
                    <div class="tooltip whitespace-normal max-w-sm rounded shadow-lg p-3 bg-gray-100 text-gray-500 -mt-16 -ml-32">
                      This instrument would be of interest to an individual or
                      organization expecting to achieve a profit upon sale.
                    </div>
                    Investor
                  </Tag>
                ) : null} */}
              </div>
              {/* <div className="has-tooltip inline-block">
                {info.valueCollector ? (
                  <Tag color="blue">
                    <div class="whitespace-normal max-w-sm tooltip rounded shadow-lg p-3 bg-gray-100 text-gray-500 -mt-20 -ml-32">
                      This instrument would be of interest to an individual or
                      organization already knowledgeable in this field and
                      seeking to increase their holdings.
                    </div>
                    Collector
                  </Tag>
                ) : null}
              </div> */}

              {/* {info.valueStudent ? <Tag color="blue">Student</Tag> : null}
              {info.valueConservatory ? (
                <Tag color="blue">Conservatory</Tag>
              ) : null}
              {info.valueArtist ? <Tag color="blue">Artist</Tag> : null} */}
            </div>

            <div className=" block  sm:hidden">
              <ImageGallery
                items={proImg}
                showPlayButton={false}
                showBullets={false}
                showIndex={false}
                showThumbnails={true}
                lazyLoad={true}
                autoPlay={false}
                showNav={false}
                showFullscreenButton={false}
              />
            </div>

            {info.moniker ? (
              <div className="py-2">
                <b>Alternative Name: </b>
                {info.moniker}
              </div>
            ) : null}

            {info.workshop ? (
              <div className="py-2">
                <b>Workshop or Manufacturer: </b>
                {info.workshop}
              </div>
            ) : null}

            {info.description ? (
              <div className="py-2" id="description">
                <b>Description:</b>
                {/* <ReadMoreReact
                  text={info.description}
                  min={120}
                  ideal={250}
                  max={300}
                  readMoreText="More"
                /> */}
                <SmartText text={info.description} length={200} />
              </div>
            ) : null}

            {info.matStick || info.matFrog || info.matWinding ? (
              <div className="py-2">
                <b>Material:</b>
                <br />
                {info.matStick ? (
                  <p>Material of Stick: {info.matStick}</p>
                ) : null}
                {info.matWinding ? (
                  <p>Material of Winding: {info.matWinding}</p>
                ) : null}
                {info.matFrog ? (
                  <p>Materials of Frog/Adjuster: {info.matFrog}</p>
                ) : null}
              </div>
            ) : null}

            {info.matIvory || info.matTorto || info.matWhale ? (
              <div className="py-2">
                <b>Endangered Species Material:</b>
               
                <br />
                {info.matIvory ? <Tag color="green">Ivory</Tag> : null}
                {info.matTorto ? <Tag color="green">Tortoiseshell</Tag> : null}
                {info.matWhale ? <Tag color="green">Whalebone</Tag> : null}
                <br />
                <a className="text-xs" href="https://cites.org/eng/disc/text.php" target="_blank" >Convention on International Trade in Endangered Species of Wild Fauna and Flora (CITES)</a>
              </div>
            ) : null}

            {info.length ||
            info.upperBout ||
            info.middleBout ||
            info.lowerBout ||
            info.stringLength ||
            info.stringStop ||
            info.weight ? (
              <div>
                <div className="py-2">
                  <b>Measurement:</b>
                </div>
                <table className="table-fixed pt-4 mb-0 text-sm ">
                  <tbody>
                    {info.length ? (
                      <tr>
                        {info.type.includes("Bow") ? (
                          <td className="border py-2 ">
                            &nbsp; &nbsp; Length{" "}
                          </td>
                        ) : (
                          <td className="border py-2 ">
                            &nbsp; &nbsp; Length of Back{" "}
                          </td>
                        )}
                        <td className="border py-2 ">{info.length} cm</td>
                      </tr>
                    ) : null}

                    {info.upperBout ? (
                      <tr>
                        <td className="border py-2 ">
                          &nbsp; &nbsp; Upper Bout{" "}
                        </td>
                        <td className="border py-2 ">{info.upperBout} cm</td>
                      </tr>
                    ) : null}

                    {info.middleBout ? (
                      <tr>
                        <td className="border py-2 ">
                          &nbsp; &nbsp; Middle Bout{" "}
                        </td>
                        <td className="border py-2 ">{info.middleBout} cm</td>
                      </tr>
                    ) : null}

                    {info.lowerBout ? (
                      <tr>
                        <td className="border py-2 ">
                          &nbsp; &nbsp; Lower Bout{" "}
                        </td>
                        <td className="border py-2 ">{info.lowerBout} cm</td>
                      </tr>
                    ) : null}

                    {info.stringStop ? (
                      <tr>
                        <td className="border py-2 ">
                          &nbsp; &nbsp; String Stop{" "}
                        </td>
                        <td className="border py-2 ">{info.stringStop} cm</td>
                      </tr>
                    ) : null}

                    {info.stringLength ? (
                      <tr className="first: px-10">
                        <td className="border px-4 py-2">
                          {" "}
                          &nbsp; &nbsp; String Length{" "}
                        </td>
                        <td className="border px-4 py-2 ">
                          {info.stringLength} cm
                        </td>
                      </tr>
                    ) : null}

                    {info.weight ? (
                      <tr>
                        <td className="border py-2 ">&nbsp; &nbsp; Weight </td>
                        <td className="border py-2 ">{info.weight} g</td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            ) : null}
          </div>
        </div>

        {info.narrative ? (
          <Row justify="space-around" align="middle">
            <Col span={24}>
              <div className="pt-8 font-16px" id="narrative">
                <div className="">
                  <b>Narrative:</b>
                </div>
                {/* <ReadMoreReact
                  sytle={{ cursor: "pointer" }}
                  text={info.narrative}
                  min={200}
                  ideal={250}
                  max={300}
                  readMoreText="More"
                /> */}
                <SmartText text={info.narrative} length={250} />
              </div>
            </Col>
          </Row>
        ) : null}

        <Divider className="m-5" />

        <br />
        <div className="grid gap-5 grid-cols-1 sm:grid-cols-10 sm:items-start max-w-screen">
          {provLst.length !== 0 ? (
            <div className="col-span-1 sm:col-span-3">
              <div className="font-semibold pb-3 text-lg">Provenance</div>
              <Timeline>
                <Loadmore visible={5} increment={50} loaded={false}>
                  {provLst}
                </Loadmore>
              </Timeline>
            </div>
          ) : null}

          <div className="col-span-1 grid gap-10 sm:col-span-7">
            {isCert ? (
              <div className="col-span-1 max-w-full sm:col-span-7">
                <div className="font-semibold pb-2 text-lg">Certificates and Documents</div>
                <Loadmore visible={3} increment={50} loaded={false}>
                  {cert}
                </Loadmore>
              </div>
            ) : null}

            {isRef ? (
              <div className="col-span-1 sm:col-span-7 ">
                <div className="font-semibold pb-2 text-lg">References</div>
                <Loadmore visible={3} increment={50} loaded={false}>
                  {reference}
                </Loadmore>
              </div>
            ) : null}

            {isPress ? (
              <div className="col-span-1 sm:col-span-7 ">
                <div className="font-semibold pb-2 text-lg">Press</div>
                <Loadmore visible={3} increment={50} loaded={false}>
                  {press}
                </Loadmore>
              </div>
            ) : null}

            {techDetail.length !== 0 ? (
              <div className="col-span-1 sm:col-span-7 ">
                <div className="font-semibold pb-2 text-lg">
                  Photos, Technical Details, and Scans
                </div>
                <Collapse>
                  {/* defaultActiveKey={['1']} */}
                  {techDetail}
                </Collapse>
              </div>
            ) : null}

            {rptLst.length !== 0 ? (
              <div className="col-span-1 sm:col-span-7 ">
                <div className="font-semibold pb-2 text-lg ">
                  Condition Report
                </div>
                <a>{rptLst}</a>
              </div>
            ) : null}

            {isVideo ? (
              <div className="col-span-1 sm:col-span-7 object-scale-down">
                <div className="font-semibold pb-2 text-lg">Videos</div>
                <Loadmore visible={3} increment={50} loaded={false}>
                  {video}
                </Loadmore>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <MediaModal
        visible={mediaModal}
        onCancel={() => {
          setMediaModal(false)
        }}
        data={mediaData}
      />
      {/* container */}
    </Layout>
  )
}
export default InsDetail

function provSort(a, b) {
  if (a.startTime === "?") {
    if (b.endTime === "?") {
      return b.startTime - a.endTime
    } else {
      return b.startTime - a.endTime || b.endTime - a.endTime
    }
  } else if (a.endTime === "?") {
    if (b.startTime === "?") {
      return b.endTime - a.startTime
    } else {
      return b.endTime - a.startTime || b.startTime - a.startTime
    }
  } else {
    if (b.startTime === "?") {
      return b.endTime - a.startTime || b.endTime - a.endTime
    } else if (b.endTime === "?") {
      return b.startTime - a.endTime || b.startTime - a.startTime
    } else {
      return b.endTime - a.endTime || b.startTime - a.startTime
    }
  }
}
